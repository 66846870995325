import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationWhitelistService {

  constructor(
      private httpClient: HttpClient,
  ) {
  }

}
