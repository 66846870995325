import {Component, HostBinding, OnInit} from '@angular/core';
import {NeedToUpdateDataService} from "./need-to-update-data.service";
import {Router} from "@angular/router";
import {empty, of, Subject} from "rxjs";
import {catchError, flatMap} from "rxjs/operators";
import {IResponse, IResponseData, IResponseItem, IResponseItems} from "../shared/interfaces/response";
import {HttpErrorResponse} from "@angular/common/http";
import {NotifierService} from "angular-notifier";
import {MatTableDataSource} from '@angular/material';
import {MultiLanguageService} from "../shared/translate/multiLanguageService";

@Component({
    selector: 'app-need-to-update-data',
    templateUrl: './need-to-update-data.component.html',
    styleUrls: ['./need-to-update-data.component.css']
})
export class NeedToUpdateDataComponent implements OnInit {
    @HostBinding('class.dv-list-page') layout = true;
    prefixName = 'migrate_data';
    refreshPageDataTrigger: Subject<any>;
    loading = true;
    configSettings: any;
    resMigration: any;
    actions: any;

    constructor(
        private dataService: NeedToUpdateDataService,
        private router: Router,
        private notifier: NotifierService,
        public multiLanguageService: MultiLanguageService,
    ) {
    }

    ngOnInit() {
        this.multiLanguageService.onSetupMultiLanguage(this.prefixName);
        this._registerEvents();
        this._triggerRefreshPageData()
    }

    private _registerEvents() {
        this.refreshPageDataTrigger = new Subject();
        this.refreshPageDataTrigger.pipe(
            flatMap(() => {
                this.loading = true;
                return this.dataService.getDataConfig().pipe(
                    catchError((error) => {
                        if (error instanceof HttpErrorResponse && error.status == 500) {
                            console.log(error);
                            this.notifier.notify('error', 'Server Error, please contact your admin or retry later');
                            this.loading = false;
                            return empty();
                        }
                        if (error instanceof HttpErrorResponse && error.status == 403) {
                            console.log(error);
                            this.notifier.notify('error', 'Permission denied, please contact your admin or retry later');
                            this.loading = false;
                            return empty();
                        }

                        const _msg = error.message ? error.message : 'Get Data failed';
                        this.notifier.notify('error', _msg);
                        this.loading = false;
                        return empty()
                    })
                )
            }),
            // debounceTime(1000),
            flatMap((res: IResponse) => {
                console.log(res);
                if (!res.success) {
                    const messages = res.messages ? res.messages.error[0] : 'Server Error, please contact your admin or retry later';
                    this.notifier.notify('error', messages);
                    this.loading = false;
                    return of(null);
                }
                this.configSettings = res.data.items;
                return of(null);
            }),
        ).subscribe(
            () => {
                this.loading = false;
            },
            (error) => {
                console.log(error);
                this.loading = false;
            },
            () => {
            }
        );
    }

    private _triggerRefreshPageData() {
        this.refreshPageDataTrigger.next();
    }

    onUpdateData(config_key) {
        this.loading = true;
        this.dataService.migrateUpdateData(config_key).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.status == 500) {
                    console.log(error);
                    this.notifier.notify('error', 'Server Error, please contact your admin or retry later');
                    this.loading = false;
                    return empty();
                }
                if (error instanceof HttpErrorResponse && error.status == 403) {
                    console.log(error);
                    this.notifier.notify('error', 'Permission denied, please contact your admin or retry later');
                    this.loading = false;
                    return empty();
                }

                const _msg = error.message ? error.message : 'Get Data failed';
                this.notifier.notify('error', _msg);
                this.loading = false;
                return empty()
            }),
            // debounceTime(1000),
            flatMap((res: IResponse) => {
                console.log(res);
                if (!res.success) {
                    const messages = res.messages ? res.messages.error[0] : 'Server Error, please contact your admin or retry later';
                    this.notifier.notify('error', messages);
                    this.loading = false;
                    return of(null);
                }
                this.resMigration = res.data;
                return of(null);
            }),
        ).subscribe(
            () => {
                this.loading = false;
                this.notifier.notify('success', 'Update data success!!');
                this._triggerRefreshPageData()
            },
            (error) => {
                console.log(error);
                this.loading = false;
            },
            () => {
            }
        );
    }

    public onRefreshClicked() {
        this._triggerRefreshPageData();
    }
}
