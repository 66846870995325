import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {of} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
      private httpClient: HttpClient
  ) { }

  public getData() {
      const url = environment.api_base_url + '/profile/';

      return this.httpClient.get(url).pipe(
          map(result => {
              return result;
          }),
          catchError(err => {
              throw err;
          })
      );
  }

  public update(data) {
      const url = environment.api_base_url + '/profile/';
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this.httpClient.patch(url, data, {headers: headers}).pipe(
          map(results => {
              return results;
          }),
          catchError(err => {
              throw err;
          })
      );
  }

  public confirmOtp(data) {
      const url = environment.api_base_url + '/profile/otp-confirm';
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this.httpClient.post(url, data, {headers: headers}).pipe(
          map(results => {
              return results;
          }),
          catchError(err => {
              throw err;
          })
      );
  }

  public validate(data) {
      const url = environment.api_base_url + '/profile/validate';
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this.httpClient.patch(url, data, {headers: headers}).pipe(
          map(results => {
              return results;
          }),
          catchError(err => {
              throw err;
          })
      );
  }
}
