import {ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, Inject, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MediaMatcher} from '@angular/cdk/layout';
import {of, Subject} from 'rxjs';
import {MatTableDataSource, PageEvent, MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatTable} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {IResponse, IResponseData, IResponseItem, IResponseItems} from '../../../shared/interfaces/response';
import {HttpErrorResponse} from '@angular/common/http';
import {flatMap} from 'rxjs/operators';
import {ListService} from "./list.service";
import {MultiLanguageService} from "../../../shared/translate/multiLanguageService";
import {RegistrationWhitelistService} from "../registration-whitelist.service";
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit, OnDestroy {
    @HostBinding('class.dv-list-page') layout = true;
    loading = true;
    prefixName = 'registration_whitelist';

    @ViewChild(MatTable) table: MatTable<any>;


    filterForm: FormGroup;

    actions: any;
    registrationWhiteListDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    selection = new SelectionModel<Selection>(true, []);

    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;

    pageIndex = 0;
    pageSize = 20;
    pageSizeOptions = [5, 10, 20, 50];
    pageLength = 0;

    allColumns = [
        'mobile_phone',
        'name',
        'organization',
        'referrer',
        'note',
    ];

    mobilePhone = '';
    name = '';
    organization = '';
    referrer = '';
    note = '';

    selectedColumns = [];
    refreshPageDataTrigger: Subject<any>;

    sortOptions = [
        {
            id: 'id',
            text: 'ID ASC'
        },
        {
            id: '-id',
            text: 'ID DESC'
        }
    ];

    arrayBuffer: any;
    file: File;
    data: any;
    configSetting: any;

    adding: boolean = false;

    constructor(
        private dataService: ListService,
        private service: RegistrationWhitelistService,
        private notifier: NotifierService,
        private formBuilder: FormBuilder,
        private router: Router,
        private titleService: Title,
        public dialog: MatDialog,
        private multiLanguageService: MultiLanguageService,
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher
    ) {
        this.multiLanguageService.onSetupMultiLanguage(this.prefixName);
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        if (this.mobileQuery.addEventListener)
            this.mobileQuery.addEventListener('', this._mobileQueryListener);
        else
            this.mobileQuery.addListener(this._mobileQueryListener);

        this.filterForm = this.formBuilder.group({
            search: [''],
            ordering: [''],
        });
    }

    ngOnInit() {
        this.selectedColumns = this.allColumns;
        this.titleService.setTitle('Registration White List')
        this._registerEvents();
        this._triggerRefreshPageData()

    }

    ngOnDestroy(): void {
        if (this.mobileQuery.removeEventListener)
            this.mobileQuery.removeEventListener('', this._mobileQueryListener);
        else
            this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    private _buildData() {
        const data = this.filterForm.getRawValue();
        data.offset = this.pageIndex * this.pageSize;
        data.limit = this.pageSize;
        return data;
    }

    private _registerEvents() {
        this.refreshPageDataTrigger = new Subject();
        this.refreshPageDataTrigger.pipe(
            flatMap(() => {
                this.loading = true;
                return this.dataService.getDataConfig()
            }),
            flatMap((res: IResponse) => {
                if (!res.success) {
                    this.pageLength = 0;
                    return of(null);
                }

                this.configSetting = res.data;

                const data = this._buildData();
                return this.dataService.getData(data)
            }),
            // debounceTime(1000),
            flatMap((res: IResponse) => {
                if (res == null)
                    return of(null);
                if (!res.success) {
                    const messages = res.messages ? res.messages.error[0] : 'Server Error, please contact your admin or retry later';
                    this.notifier.notify('error', messages);
                    this.loading = false;
                    return of(null);
                }
                this._parseData(res.data);
                return of(null);
            }),
        ).subscribe(
            () => {
                this.loading = false;
            },
            (error) => {
                if (error instanceof HttpErrorResponse && error.status == 500) {
                    console.log(error);
                    this.notifier.notify('error', 'Server Error, please contact your admin or retry later');
                    this.loading = false;
                    return;
                }
                if (error instanceof HttpErrorResponse && error.status == 403) {
                    console.log(error);
                    this.loading = false;
                    return this.router.navigate(['/authentication/403'], {replaceUrl: true});
                }

                const _msg = error.message ? error.message : 'Get Data failed';
                this.notifier.notify('error', _msg);
                this.loading = false;
            },
            () => {
            }
        );
    }

    private _triggerRefreshPageData() {
        this.adding = false;
        this.refreshPageDataTrigger.next();
    }

    private _parseData(data: IResponseItems) {
        this.pageLength = data.pagination.total;
        this.actions = data.actions;
        this.registrationWhiteListDataSource.data = data.items;
    }

    get displayColumns() {
        return ['select'].concat(this.selectedColumns).concat(['actions']);
    }

    public onPageChange(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this._triggerRefreshPageData();
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.registrationWhiteListDataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.registrationWhiteListDataSource.data.forEach(row => this.selection.select(row));
    }

    public onFilterSubmit() {
        this.pageIndex = 0;
        this._triggerRefreshPageData();
    }

    public onFilterClear() {
        this.filterForm.reset();
    }

    public onRefreshClicked() {
        this._triggerRefreshPageData();
    }

    public incomingFile(event) {
        this.file = event.target.files[0];
        this.readFile();
    }

    public readFile() {
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, {type: "binary"});
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            this.data = XLSX.utils.sheet_to_json(worksheet);

            const dialogRef = this.dialog.open(RegistrationWhiteListDataExcelDialogComponent, {
                data: this.data,
                width: '80%',
                height: '80%',
                panelClass: 'dv-flex-dialog',
            });

            dialogRef.afterClosed().subscribe(response => {
                if (response) {
                    this._triggerRefreshPageData();
                }
            });
        };
        fileReader.readAsArrayBuffer(this.file);
    }

    onEnableConfigClicked(version) {
        this.loading = true;
        let data = {
            'version': version
        };
        this.dataService.enableConfig(data).subscribe(
            (response: IResponse) => {
                if (response == null) {
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }

                if (!response.success) {
                    let msg;
                    try {
                        msg = response.errors[0];
                    } catch (error) {
                    }
                    if (msg == null) msg = 'Enabled failed! Please contact your admin or retry later';
                    this.notifier.notify('error', msg);
                    this.loading = false;
                    return;
                }

                let msg = 'Registration whitelist enabled';
                try {
                    msg = response.messages.success[0]
                } catch (error) {
                }
                this.notifier.notify('success', msg);
                this.loading = false;
                this._triggerRefreshPageData();
            },
            (error) => {
                if (error instanceof HttpErrorResponse && error.status == 500) {
                    console.log(error);
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }
                if (error instanceof HttpErrorResponse && error.status == 403) {
                    console.log(error);
                    this.notifier.notify('error', 'You don\`t have permission to perform this action. Please contact your admin for further information');
                    this.loading = false;
                    return;
                }

                const msg = error.message ? error.message : 'Registration whitelist enabled failed';
                this.notifier.notify('error', msg);
                this.loading = false;
            },
            () => {
            }
        );
    }

    onDisableConfigClicked(version) {
        this.loading = true;
        let data = {
            'version': version
        };
        this.dataService.disableConfig(data).subscribe(
            (response: IResponse) => {
                if (response == null) {
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }

                if (!response.success) {
                    let msg;
                    try {
                        msg = response.errors[0];
                    } catch (error) {
                    }
                    if (msg == null) msg = 'Disabled failed! Please contact your admin or retry later';
                    this.notifier.notify('error', msg);
                    this.loading = false;
                    return;
                }

                let msg = 'Registration whitelist disabled';
                try {
                    msg = response.messages.success[0]
                } catch (error) {
                }
                this.notifier.notify('success', msg);
                this.loading = false;
                this._triggerRefreshPageData();
            },
            (error) => {
                if (error instanceof HttpErrorResponse && error.status == 500) {
                    console.log(error);
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }
                if (error instanceof HttpErrorResponse && error.status == 403) {
                    console.log(error);
                    this.notifier.notify('error', 'You don\`t have permission to perform this action. Please contact your admin for further information');
                    this.loading = false;
                    return;
                }

                const msg = error.message ? error.message : 'Registration whitelist disabled failed';
                this.notifier.notify('error', msg);
                this.loading = false;
            },
            () => {
            }
        );
    }

    onDeleteClicked(id) {
        this.loading = true;

        this.dataService.delete(id).subscribe(
            (response: IResponseData) => {
                if (response == null) {
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }

                if (!response.success) {
                    let msg;
                    try {
                        msg = response.errors[0];
                    } catch (error) {
                    }
                    if (msg == null) msg = 'Delete failed! Please contact your admin or retry later';
                    this.notifier.notify('error', msg);
                    this.loading = false;
                    return;
                }

                let msg = 'Deleted successfully';
                try {
                    msg = response.messages.success[0]
                } catch (error) {
                }
                this.notifier.notify('success', msg);
                this.loading = false;
                this._triggerRefreshPageData();
            },
            (error) => {
                if (error instanceof HttpErrorResponse && error.status == 500) {
                    console.log(error);
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }
                if (error instanceof HttpErrorResponse && error.status == 403) {
                    console.log(error);
                    this.notifier.notify('error', 'You don\`t have permission to perform this action. Please contact your admin for further information');
                    this.loading = false;
                    return;
                }

                const msg = error.message ? error.message : 'Registration whitelist deleted failed';
                this.notifier.notify('error', msg);
                this.loading = false;
            },
            () => {
            }
        );
    }

    onEditClicked(row) {
        this.registrationWhiteListDataSource.data.forEach(o => o.item['edit'] = false);
        this.mobilePhone = row.item['mobile_phone'];
        this.name = row.item['name'];
        this.organization = row.item['organization'];
        this.referrer = row.item['referrer'];
        this.note = row.item['note'];
        row.item['edit'] = true;
        this.table.renderRows();
    }

    onUpdateClicked(row) {
        if (this.mobilePhone.trim() == '') {
            this.notifier.notify('error', 'Mobile phone field cannot be empty');
            return;
        }

        this.adding = false;
        this.loading = true;
        let data = {
            'mobile_phone': this.mobilePhone,
            'name': this.name,
            'organization': this.organization,
            'referrer': this.referrer,
            'note': this.note,
        };
        this.dataService.update(row.item.text_id, data).subscribe(
            (response: IResponseData) => {
                if (response == null) {
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }

                if (!response.success) {
                    let msg;
                    try {
                        msg = response.errors[0];
                    } catch (error) {
                    }
                    if (msg == null) msg = 'Updated failed! Please contact your admin or retry later';
                    this.notifier.notify('error', msg);
                    this.loading = false;
                    return;
                }

                let msg = 'Registration whitelist updated';
                try {
                    msg = response.messages.success[0]
                } catch (error) {
                }
                this.notifier.notify('success', msg);
                this.loading = false;
                row.item = response.data.item;
                this.table.renderRows();
            },
            (error) => {
                if (error instanceof HttpErrorResponse && error.status == 500) {
                    console.log(error);
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }
                if (error instanceof HttpErrorResponse && error.status == 403) {
                    console.log(error);
                    this.notifier.notify('error', 'You don\`t have permission to perform this action. Please contact your admin for further information');
                    this.loading = false;
                    return;
                }

                const msg = error.message ? error.message : 'Registration whitelist updated failed';
                this.notifier.notify('error', msg);
                this.loading = false;
            },
            () => {
            }
        );
    }

    onCancelEditClicked(row) {
        row.item['edit'] = false;
        this.table.renderRows();
    }

    onInsertClicked() {
        if (this.adding) return;
        let newRowObject = {
            'item': {
                'insert': true
            }
        };

        this.mobilePhone = '';
        this.name = '';
        this.organization = '';
        this.referrer = '';
        this.note = '';

        this.adding = true;

        this.registrationWhiteListDataSource.data.unshift(newRowObject);
        this.table.renderRows();
    }

    onAddClicked() {
        if (this.mobilePhone.trim() == '') {
            this.notifier.notify('error', 'Mobile phone field cannot be empty');
            return;
        }

        this.adding = false;
        this.loading = true;
        let data = {
            'mobile_phone': this.mobilePhone,
            'name': this.name,
            'organization': this.organization,
            'referrer': this.referrer,
            'note': this.note,
        };
        this.dataService.insert(data).subscribe(
            (response: IResponseData) => {
                if (response == null) {
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }

                if (!response.success) {
                    let msg;
                    try {
                        msg = response.errors[0];
                    } catch (error) {
                    }
                    if (msg == null) msg = 'Insert failed! Please contact your admin or retry later';
                    this.notifier.notify('error', msg);
                    this.loading = false;
                    return;
                }

                let msg = 'Registration whitelist insert';
                try {
                    msg = response.messages.success[0]
                } catch (error) {
                }
                this.notifier.notify('success', msg);
                this.loading = false;
                this.registrationWhiteListDataSource.data.shift();
                this.registrationWhiteListDataSource.data.unshift(response.data);
                this.table.renderRows();
            },
            (error) => {
                if (error instanceof HttpErrorResponse && error.status == 500) {
                    console.log(error);
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }
                if (error instanceof HttpErrorResponse && error.status == 403) {
                    console.log(error);
                    this.notifier.notify('error', 'You don\`t have permission to perform this action. Please contact your admin for further information');
                    this.loading = false;
                    return;
                }

                const msg = error.message ? error.message : 'Registration whitelist insert failed';
                this.notifier.notify('error', msg);
                this.loading = false;
            },
            () => {
            }
        );
    }

    onCancelClicked() {
        this.adding = false;
        this.registrationWhiteListDataSource.data.shift();
        this.table.renderRows();
    }

    onDeleteBulkClicked() {
        let listId = [];
        this.selection.selected.forEach(o => listId.push(o['item'].text_id));

        this.loading = true;

        this.dataService.deleteBulk({'list_id': listId}).subscribe(
            (response: IResponseData) => {
                if (response == null) {
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }

                if (!response.success) {
                    let msg;
                    try {
                        msg = response.errors[0];
                    } catch (error) {
                    }
                    if (msg == null) msg = 'Delete failed! Please contact your admin or retry later';
                    this.notifier.notify('error', msg);
                    this.loading = false;
                    return;
                }

                let msg = 'Deleted successfully';
                try {
                    msg = response.messages.success[0]
                } catch (error) {
                }
                this.notifier.notify('success', msg);
                this.loading = false;
                this.selection = new SelectionModel<Selection>(true, []);
                this._triggerRefreshPageData();
            },
            (error) => {
                if (error instanceof HttpErrorResponse && error.status == 500) {
                    console.log(error);
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }
                if (error instanceof HttpErrorResponse && error.status == 403) {
                    console.log(error);
                    this.notifier.notify('error', 'You don\`t have permission to perform this action. Please contact your admin for further information');
                    this.loading = false;
                    return;
                }

                const msg = error.message ? error.message : 'Registration whitelist deleted failed';
                this.notifier.notify('error', msg);
                this.loading = false;
            },
            () => {
            }
        );
    }
}


@Component({
    selector: 'registration-whitelist-data-excel-dialog',
    templateUrl: 'registration-whitelist-data-excel-dialog.html',
})
export class RegistrationWhiteListDataExcelDialogComponent {
    @HostBinding('class') classes = 'diva dv-list-page';

    prefixName = 'registration_whitelist.dialog';
    loading = false;

    label_warning = '';
    data = [];
    dataSource = [];
    selectOptionImport = 'Ignore';

    displayColumns = [
        'mobile_phone',
        'name',
        'organization',
        'referrer',
        'note',
        'status',
    ];

    constructor(
        private multiLanguageService: MultiLanguageService,
        private service: RegistrationWhitelistService,
        private notifier: NotifierService,
        private dataService: ListService,
        public dialogRef: MatDialogRef<RegistrationWhiteListDataExcelDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public mat_data
    ) {
        for (let i = 0; i < mat_data.length; i++) {
            let data = {
                'name': mat_data[i]['Name'],
                'organization': mat_data[i]['Organization'],
                'referrer': mat_data[i]['Referrer'],
                'mobile_phone': mat_data[i]['Mobile phone'],
                'note': mat_data[i]['Note'],
            };
            this.data.push(data);
        }
        this.onCheckedData();
    }

    onImportClicked() {
        switch (this.selectOptionImport) {
            case 'Ignore':
                this.onImportIgnoreIfExist();
                break;
            case 'Overwrite':
                this.onImportOverwriteIfExist();
                break;
            default:
                break;
        }
    }

    onCheckedData() {
        this.loading = true;
        this.dataService.checkedData({'data': this.data}).subscribe(
            (response: IResponse) => {
                if (response == null) {
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }

                if (!response.success) {
                    let msg;
                    try {
                        msg = response.errors[0];
                    } catch (error) {
                    }
                    if (msg == null) msg = 'Import failed! Please contact your admin or retry later';
                    this.notifier.notify('error', msg);
                    this.loading = false;
                    return;
                }

                this.dataSource = response.data;

                this.loading = false;
            },
            (error) => {
                if (error instanceof HttpErrorResponse && error.status == 500) {
                    console.log(error);
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }
                if (error instanceof HttpErrorResponse && error.status == 403) {
                    console.log(error);
                    this.notifier.notify('error', 'You don\`t have permission to perform this action. Please contact your admin for further information');
                    this.loading = false;
                    return;
                }

                const msg = error.message ? error.message : 'Registration whitelist import failed';
                this.notifier.notify('error', msg);
                this.loading = false;
            },
            () => {
            }
        );
    }

    onImportIgnoreIfExist() {
        this.loading = true;
        const data = this.dataSource.filter(o => o['status'] == 'empty');
        this.dataService.importIgnoreIfExist({'data': data}).subscribe(
            (response: IResponse) => {
                if (response == null) {
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }

                if (!response.success) {
                    let msg;
                    try {
                        msg = response.errors[0];
                    } catch (error) {
                    }
                    if (msg == null) msg = 'Import failed! Please contact your admin or retry later';
                    this.notifier.notify('error', msg);
                    this.loading = false;
                    return;
                }

                let msg = 'Registration whitelist import';
                try {
                    msg = response.messages.success[0]
                } catch (error) {
                }
                this.notifier.notify('success', msg);
                this.loading = false;
                this.dialogRef.close(true);
            },
            (error) => {
                if (error instanceof HttpErrorResponse && error.status == 500) {
                    console.log(error);
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }
                if (error instanceof HttpErrorResponse && error.status == 403) {
                    console.log(error);
                    this.notifier.notify('error', 'You don\`t have permission to perform this action. Please contact your admin for further information');
                    this.loading = false;
                    return;
                }

                const msg = error.message ? error.message : 'Registration whitelist import failed';
                this.notifier.notify('error', msg);
                this.loading = false;
            },
            () => {
            }
        );
    }

    onImportOverwriteIfExist() {
        this.loading = true;
        const data = this.dataSource.filter(o => o['status'] != 'incorrect_format');
        this.dataService.importOverwriteIfExist({'data': data}).subscribe(
            (response: IResponse) => {
                if (response == null) {
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }

                if (!response.success) {
                    let msg;
                    try {
                        msg = response.errors[0];
                    } catch (error) {
                    }
                    if (msg == null) msg = 'Import failed! Please contact your admin or retry later';
                    this.notifier.notify('error', msg);
                    this.loading = false;
                    return;
                }

                let msg = 'Registration whitelist import';
                try {
                    msg = response.messages.success[0]
                } catch (error) {
                }
                this.notifier.notify('success', msg);
                this.loading = false;
                this.dialogRef.close(true);
            },
            (error) => {
                if (error instanceof HttpErrorResponse && error.status == 500) {
                    console.log(error);
                    this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
                    this.loading = false;
                    return;
                }
                if (error instanceof HttpErrorResponse && error.status == 403) {
                    console.log(error);
                    this.notifier.notify('error', 'You don\`t have permission to perform this action. Please contact your admin for further information');
                    this.loading = false;
                    return;
                }

                const msg = error.message ? error.message : 'Registration whitelist import failed';
                this.notifier.notify('error', msg);
                this.loading = false;
            },
            () => {
            }
        );
    }
}