import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import {HomeComponent} from './home/home.component';
import {ProfileComponent} from './profile/profile.component';
import {NeedToUpdateDataComponent} from "./need-to-update-data/need-to-update-data.component";

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'need-to-update-data',
        component: NeedToUpdateDataComponent
      },
      {
        path: 'sysadmin',
        loadChildren: './sysadmin/sysadmin.module#SysadminModule'
      },
      {
        path: 'accountant',
        loadChildren: './accountant/accountant.module#AccountantModule'
      },
      {
          path: 'wallet',
          loadChildren: './wallet/wallet.module#WalletModule'
      },
      {
        path: 'payment-method-settings',
        loadChildren: './payment-method-settings/payment-method-settings.module#PaymentMethodSettingsModule'
      },
      {
        path: 'bank-accounts',
        loadChildren: './bank-accounts/bank-accounts.module#BankAccountsModule'
      },
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      },
      {
        path: 'invite',
        loadChildren:
            './invite/invite.module#InviteModule'
      },
      {
        path: 'change-email',
        loadChildren:
            './change-email/change-email.module#ChangeEmailModule'
      },
      {
        path: 'profile',
        component: ProfileComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
];
