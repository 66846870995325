import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {catchError, map} from "rxjs/operators";
@Injectable({
    providedIn: 'root'
})

export class NeedToUpdateDataService {

    constructor(private httpClient: HttpClient,) {
    }

    getDataConfig() {
        const url = environment.api_base_url + '/configuration-settings/';

        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                throw err;
            })
        );
    }

    migrateUpdateData(config_key) {
        let url = '';
        switch (config_key) {
            case "MIGRATION.B2W_TRANSACTION":
                url = environment.api_base_url + '/migrate-data/transaction/b2w-update-data';
                break;
            case "MIGRATION.ADDITIONAL_DATA_VB":
                url = environment.api_base_url + '/migrate-data/additional-data/update';
                break;
            case "MIGRATION.REFRESH_REPORT_TABLE":
                url = environment.api_base_url + '/migrate-data/refresh-report-table/all';
                break;
            case "MIGRATION.UPDATE_TEXT_ID_GUARANTEED_ACCOUNT_TABLE":
                url = environment.api_base_url + '/migrate-data/update-guaranteed-account-table';
                break;
            default:
                break
        }
        return this.httpClient.post(url, null).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                throw err;
            })
        );
    }
}
