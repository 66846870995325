import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ListService {

    constructor(
        private httpClient: HttpClient,
    ) {
    }

    getDataConfig() {
        const url = environment.api_base_url + '/configuration-settings/registration-whitelist';

        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                throw err;
            })
        );
    }

    getData(data) {
        const url = environment.api_base_url + '/registration-whitelist/';

        const params = this._getListBuildParams(data);

        return this.httpClient.get(url, {
            params: params
        }).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                throw err;
            })
        );
    }

    private _getListBuildParams(data) {
        const encodedData = jQuery.param(data);
        return new HttpParams({fromString: encodedData})
            ;
    }

    public checkedData(data) {
        const url = environment.api_base_url + '/registration-whitelist/checked_data';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public importIgnoreIfExist(data) {
        const url = environment.api_base_url + '/registration-whitelist/import-ignore-if-exist';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public importOverwriteIfExist(data) {
        const url = environment.api_base_url + '/registration-whitelist/import-overwrite-if-exist';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public enableConfig(data) {
        const url = environment.api_base_url + '/configuration-settings/registration-whitelist/enable';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public disableConfig(data) {
        const url = environment.api_base_url + '/configuration-settings/registration-whitelist/disable';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public delete(id) {
        const url = environment.api_base_url + '/registration-whitelist/' + id;
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.delete(url, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public deleteBulk(data) {
        const url = environment.api_base_url + '/registration-whitelist/delete';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public insert(data) {
        const url = environment.api_base_url + '/registration-whitelist/create';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public update(id, data) {
        const url = environment.api_base_url + '/registration-whitelist/' + id + '/update';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }
}
