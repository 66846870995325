import {Component, HostBinding, OnInit, Inject} from '@angular/core';
import {Router} from "@angular/router";
import {Subject, of} from 'rxjs';
import {NotifierService} from 'angular-notifier';
import {Title} from '@angular/platform-browser';
import {ProfileService} from "./profile.service";
import {HttpErrorResponse} from '@angular/common/http';
import {flatMap} from 'rxjs/operators';
import {IResponse} from "../shared/interfaces/response";
import {MultiLanguageService} from "../shared/translate/multiLanguageService";
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
    title = 'Profile';
    prefixName = 'profile';

    @HostBinding('class.dv-list-page') layout = true;
    loading = true;

    detailData;

    isEditingName = false;
    inputFirstName;
    inputLastName;
    isSaveButtonDisabled = false;

    data = [
        {
            name: 'email',
            isEditing: false,
            input: '',
            value: '',
            verification_status: '',
            isSaveButtonDisabled: false,
            type: 'text',
        },
        {
            name: 'mobile_phone',
            isEditing: false,
            input: '',
            value: '',
            verification_status: '',
            isSaveButtonDisabled: false,
            type: 'phone',
        },
    ];

    refreshPageDataTrigger: Subject<any>;

    constructor(
        private dataService: ProfileService,
        private notifier: NotifierService,
        private router: Router,
        private titleService: Title,
        private multiLanguageService: MultiLanguageService,
        public dialog: MatDialog,
    ) {
    }

    ngOnInit() {
        this.multiLanguageService.onSetupMultiLanguage(this.prefixName);
        this.titleService.setTitle(this.title);
        this.__registerEvents();
        this.__triggerRefreshPageData();
    }

    public onRefreshClicked() {
        this.__triggerRefreshPageData();
    }

    public beginEditing(obj) {
        this.onCloseEditDetail();
        obj.isEditing = true;
        obj.input = obj.value;
        this.onChanged(obj);
    }

    public onChanged(obj) {
        obj.isSaveButtonDisabled = !obj.input;
    }

    public onSaveEdit(obj) {
        const data = {};

        obj.isSaveButtonDisabled = false;
        if (!obj.input) {
            this.multiLanguageService.get(this.prefixName + '.incorrect_data_format').subscribe((res: string) => {
                this.notifier.notify('error', res);
            });
            this.isSaveButtonDisabled = true;
            return;
        }

        data[obj.name] = obj.input;
        data['version'] = this.detailData.version;

        this.dataService.validate(data).subscribe((response : IResponse) => {
            if (response.success) {
                this.onDialogConfirmPassword(data, '', obj);
            } else {
                if (response['warning'])
                    this.onDialogConfirmPassword(data, this.prefixName + '.' + response.messages['errors'][0], obj);
                else
                    this.multiLanguageService.get(this.prefixName + '.' + response.messages['errors'][0]).subscribe((res: string) => {
                        this.notifier.notify('success', res);
                    });
            }
        });
    }

    onDialogConfirmPassword(data, label_warning, obj) {
        const dialogRef = this.dialog.open(ConfirmPassWordDialogComponent, {
            data: {
                label_warning: label_warning,
                data: data
            },
            width: '400px',
            height: '220px',
            panelClass: 'dv-flex-dialog',
        });

        dialogRef.afterClosed().subscribe(response => {
            if (response) {
                if (response.mobile_phone && response.token) {
                    this.onDialogConfirmOtp(response.token);
                }
                else {
                    this.__triggerRefreshPageData();
                }
                obj.isEditing = false;
            }
        });
    }

    onDialogConfirmOtp(tokenKey) {
        const dialogRef = this.dialog.open(ConfirmOtpDialogComponent, {
            data: tokenKey,
            width: '400px',
            height: '220px',
            panelClass: 'dv-flex-dialog',
        });

        dialogRef.afterClosed().subscribe(response => {
            if (response) {
                this.__triggerRefreshPageData();
            }
        });
    }

    public beginEditingName() {
        this.onCloseEditDetail();
        this.isEditingName = true;
        this.inputFirstName = this.detailData.first_name;
        this.inputLastName = this.detailData.last_name;
        this.onNameChanged();
    }

    public onNameChanged () {
        this.isSaveButtonDisabled = !this.inputFirstName || !this.inputLastName;
    }

    public onSaveEditDetail() {
        const data = {};

        this.isSaveButtonDisabled = false;
        if (!this.inputFirstName) {
            this.isSaveButtonDisabled = true;
            return;
        }

        if (!this.inputLastName) {
            this.isSaveButtonDisabled = true;
            return;
        }

        this.loading=true;

        data['first_name'] = this.inputFirstName;
        data['last_name'] = this.inputLastName;
        data['version'] = this.detailData.version;
        this.dataService.update(data).subscribe((response : IResponse) => {
            this.__triggerRefreshPageData();
            if (response.success) {
                this.isEditingName = false;
                this.multiLanguageService.get(this.prefixName + '.' + response.messages).subscribe((res: string) => {
                    this.notifier.notify('success', res);
                });
            } else {
                this.multiLanguageService.get(this.prefixName + '.' + response.messages['errors'][0]).subscribe((res: string) => {
                    this.notifier.notify('error', res);
                });
            }
        });
    }

    public onCloseEditDetail() {
        this.isEditingName = false;
        this.data.forEach(obj => {
           obj.isEditing = false;
        });
    }

    private __registerEvents() {
        this.refreshPageDataTrigger = new Subject();
        this.refreshPageDataTrigger.pipe(
            flatMap(() => {
                this.loading = true;
                return this.dataService.getData();
            }),
            flatMap((response: IResponse) => {
                if (!response.success) {
                    const messages = response.messages ? this.prefixName + response.messages['errors'][0] : 'Server Error, please contact your admin or retry later';
                    this.multiLanguageService.get(messages).subscribe((res: string) => {
                        this.notifier.notify('error', res);
                    });
                    this.loading = false;
                    return;
                }

                this.__parseData(response);
                return of(null);
            }),
        ).subscribe(
            () => {
                this.loading = false;
            },
            (error) => {
                if (error instanceof HttpErrorResponse && error.status == 500) {
                    console.log(error);
                    this.multiLanguageService.get('Server Error, please contact your admin or retry later').subscribe((res: string) => {
                        this.notifier.notify('error', res);
                    });
                    this.loading = false;
                    return;
                }
                if (error instanceof HttpErrorResponse && error.status == 403) {
                    console.log(error);
                    this.loading = false;
                    return this.router.navigate(['/authentication/403']);
                }

                const msg = error.message ? this.prefixName + error.message : 'Getting data failed';
                this.multiLanguageService.get(msg).subscribe((res: string) => {
                    this.notifier.notify('error', res);
                });
                this.loading = false;
            },
            () => {
            }
        );
    }

    private __triggerRefreshPageData() {
        this.refreshPageDataTrigger.next();
    }

    private __parseData(response) {
        this.detailData = response.data;
        this.data.forEach(obj => {
            switch(obj.name) {
                case "email":
                    if (response.email) {
                        obj.value = response.email;
                        obj.verification_status = 'NOT_VERIFIED';
                    }
                    else {
                        obj.value = this.detailData.email;
                        obj.verification_status = this.detailData.email_verification_status;
                    }
                    break;
                case "mobile_phone":
                    if (response.mobile_phone) {
                        obj.value = response.mobile_phone;
                        obj.verification_status = 'NOT_VERIFIED';
                    }
                    else {
                        obj.value = this.detailData.mobile_phone;
                        obj.verification_status = this.detailData.mobile_phone_verification_status;
                    }
                    break;
                default:
                    break;
            }
        });
    }
}


@Component({
    selector: 'confirm-password-dialog',
    templateUrl: 'confirm-password-dialog.html',
})
export class ConfirmPassWordDialogComponent {
    @HostBinding('class') classes = 'diva dv-list-page';

    prefixName = 'profile';
    loading = false;
    inputPassword = '';

    label_warning = '';
    data = {};

    constructor(
        private multiLanguageService: MultiLanguageService,
        private dataService: ProfileService,
        private notifier: NotifierService,
        public dialogRef: MatDialogRef<ConfirmOtpDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public mat_data
    ) {
        this.label_warning = this.mat_data.label_warning;
        this.data = this.mat_data.data;
    }

    onNoClick() {
        this.dialogRef.close();
    }

    onYesClick() {
        this.loading = true;
        this.data['password'] = this.inputPassword;
        this.dataService.update(this.data).subscribe((response: IResponse) => {
            this.loading = false;
            if (response.success) {
                if (!response['mobile_phone']) {
                    this.multiLanguageService.get(this.prefixName + '.messages_success').subscribe((res: string) => {
                        this.notifier.notify('success', res);
                    });
                }
                this.dialogRef.close(response);
            } else {
                this.multiLanguageService.get(this.prefixName + '.' + response.messages['errors'][0]).subscribe((res: string) => {
                    this.notifier.notify('error', res);
                });
            }
        });
    }

}


@Component({
    selector: 'confirm-otp-dialog',
    templateUrl: 'confirm-otp-dialog.html',
})
export class ConfirmOtpDialogComponent {
    @HostBinding('class') classes = 'diva dv-list-page';

    prefixName = 'profile';
    loading = false;
    inputOtp = '';

    label_warning = '';
    data = {};

    constructor(
        private multiLanguageService: MultiLanguageService,
        private dataService: ProfileService,
        private notifier: NotifierService,
        public dialogRef: MatDialogRef<ConfirmPassWordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public mat_data
    ) {
        this.label_warning = this.mat_data.label_warning;
    }

    onNoClick() {
        this.dialogRef.close();
    }

    onYesClick() {
        this.loading = true;
        this.data['key'] = this.mat_data;
        this.data['value'] = this.inputOtp;
        this.dataService.confirmOtp(this.data).subscribe((response: IResponse) => {
            this.loading = false;
            if (response.success) {
                this.multiLanguageService.get(this.prefixName + '.messages_success').subscribe((res: string) => {
                    this.notifier.notify('success', res);
                });
                this.dialogRef.close(response);
            } else {
                this.multiLanguageService.get(this.prefixName + '.' + response.messages['errors'][0]).subscribe((res: string) => {
                    this.notifier.notify('error', res);
                });
            }
        });
    }

}